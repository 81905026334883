<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form label-width="80px">
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.formData.searchType ? '当前：客户公司销售排名' :'当前：业务员销售排名' }}</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :lg="8" :sm="12" :md="8">
                                <el-date-picker
                                        v-model="pickerMonthRange"
                                        style=" margin-top: 8px;padding-top: 2px; width: 200px"
                                        type="monthrange"
                                        align="right"
                                        size="mini"
                                        @change="$_pickerMonthRangeEvent()"
                                        unlink-panels
                                        range-separator="至"
                                        start-placeholder="开始月份"
                                        end-placeholder="结束月份"
                                        :picker-options="pickerOptions">
                                </el-date-picker>
                                <el-date-picker
                                        style=" margin-top: 8px; width: 220px"
                                        v-model="pickerDate"
                                        type="daterange"
                                        size="mini"
                                        :clearable="false"
                                        :picker-options="pickerOptions"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        align="right">
                                </el-date-picker>
                            </el-col>

                            <el-col :lg="4" >
                                <el-button style=" margin-top: 8px; " type="primary" round  size="mini" @click="searchList()">搜索查询</el-button>
                                <el-button style=" margin-top: 8px; " type="primary" round :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null"
                                           @click="open($refs.xGrid.getCurrentRecord())" size="mini">查看
                                </el-button>
                            </el-col>

                            <el-col :lg="6" :sm="12" :md="8">
                                <el-row>
                                    <el-col class="pull-left" :lg="4">
                                        <vxe-toolbar class="pull-left" style=" height: 42px; " custom export ref="xToolbar2">
                                        </vxe-toolbar>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <vxe-grid
                                border
                                resizable
                                show-overflow
                                keep-source
                                id="xGrid"
                                ref="xGrid"
                                size="mini"
                                align="right"
                                :height="(this.getViewHeight() - 130)+''"
                                :custom-config="{storage:true}"
                                highlight-hover-row
                                highlight-current-row
                                show-footer
                                :footer-method="footerMethod"
                                :cell-class-name="cellClassName"
                                :print-config="{}"
                                :data="tableDataMain"
                                :columns="tableColumn"
                                :mouse-config="{selected: false}"
                                :edit-config="{showIcon:true,trigger: 'click', mode: 'cell', showStatus: true}"
                                :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:true}">
                            <template #pager>
                                <vxe-pager
                                        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="[50,500,1000,5000,10000]"
                                        :current-page.sync="tablePage.currentPage"
                                        :page-size.sync="tablePage.pageSize"
                                        :total="tablePage.total"
                                        @page-change="handlePageChange">
                                </vxe-pager>
                            </template>
                        </vxe-grid>
                    </el-col>


                </el-row>

            </el-form>
        </el-main>
    </div>
</template>

<script>
    import XEUtils from 'xe-utils'
    import {filterRender} from "@/utils/gird-filter";

    export default {

        data() {
            var now = new Date();
            var year = now.getFullYear();
            var month = now.getMonth() + 1;
            if (month < 10) {
                month = '0' + month;
           }
            // 本月第一天
            var startDate = new Date(year + '-' + month + '-01 00:00:00');
            return {
                pickerMonthRange: [startDate, new Date()],
                pickerDate: [startDate,new Date(year + '-' + month + '-' + new Date(year, month, 0).getDate() + ' 23:59:00')],
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
               },
                formData:{
                    searchType:'',
                },
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '本月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth() + 1;
                            if (month < 10) {
                                month = '0' + month;
                           }
                            // 本月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            picker.$emit('pick', [startDate, new Date(year + '-' + month + '-' + new Date(year, month, 0).getDate() + ' 23:59:00')]);
                       }
                   }, {
                        text: '上个月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth();
                            if (month == 0) {
                                month = 12;
                                year = year - 1;
                           }
                            if (month < 10) {
                                month = '0' + month;
                           }
                            var myDate = new Date(year, month, 0);
                            // 上个月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            // 上个月最后一天
                            var endDate = new Date(year + '-' + month + '-' + myDate.getDate() + ' 23:59:00');
                            picker.$emit('pick', [startDate, endDate]);
                       }
                   }]
               },
                tableColumn: [
                    {type: 'seq', title: '序号', className: 'unEditor', width: 40},
                    {
                        field:'sales_man', width: 70, title: '业务员', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/salesRanking/searchHeard', this.pickerDate,this.formData,null,this.getList)
                       }
                   },{
                        field:'customer', width: 220, title: '客户', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/salesRanking/searchHeard', this.pickerDate,this.formData,null,this.getList)
                        }
                    },{
                        field:'single_count', width: 100, title: '支数(不开票)', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/salesRanking/searchHeard', this.pickerDate,this.formData,null,this.getList)
                       }
                   },{
                        field:'weighing_heavy', width: 100, title: '吨数(不开票)', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/salesRanking/searchHeard', this.pickerDate,this.formData,null,this.getList)
                       }
                   }, {
                        field:'kick_back', width: 100, title: '佣金(不开票)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/salesRanking/searchHeard', this.pickerDate,this.formData,null,this.getList)
                       }
                   },
                    {
                        field:'taxes_fees',visible : false, width: 130, title: '税金(不开票)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                        }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/salesRanking/searchHeard', this.pickerDate,this.formData,null,this.getList)
                        }
                    },
                    {
                        field:'amount_of_product', width: 130, title: '产品金额(不开票)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                        }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/salesRanking/searchHeard', this.pickerDate,this.formData,null,this.getList)
                        }
                    },
                    {
                        field:'naked_price', width: 130, title: '产品裸价(不开票)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                        }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/salesRanking/searchHeard', this.pickerDate,this.formData,null,this.getList)
                        }
                    },
                    {
                        field:'money', width: 130, title: '金额(不开票)', titleHelp: {message: '金额 = 产品裸价(不开票) + 佣金(不开票)'},formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                        }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/salesRanking/searchHeard', this.pickerDate,this.formData,null,this.getList)
                        }
                    },
                    {
                        field:'single_count_tax', width: 100, title: '支数(开票)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/salesRanking/searchHeard', this.pickerDate,this.formData,null,this.getList)
                       }
                   }, {
                        field:'weighing_heavy_tax', width: 100, title: '吨数(开票)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 3})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/salesRanking/searchHeard', this.pickerDate,this.formData,null,this.getList)
                       }
                   }, {
                        field:'kick_back_tax', width: 100, title: '佣金(开票)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/salesRanking/searchHeard', this.pickerDate,this.formData,null,this.getList)
                       }
                   }, {
                        field:'taxes_fees_tax',width: 130, title: '税金', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                        }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/salesRanking/searchHeard', this.pickerDate,this.formData,null,this.getList)
                        }
                    },{
                        field:'amount_of_product_tax', width: 130, title: '产品金额(开票)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/salesRanking/searchHeard', this.pickerDate,this.formData,null,this.getList)
                       }
                   },{
                        field:'naked_price_tax', width: 130, title: '产品裸价(开票)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                        }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/salesRanking/searchHeard', this.pickerDate,this.formData,null,this.getList)
                        }
                    },{
                        field:'money_tax', width: 130, title: '金额(开票)', titleHelp: {message: '金额 = 产品裸价(开票) + 佣金(开票) + 税金'}, formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                        }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/salesRanking/searchHeard', this.pickerDate,this.formData,null,this.getList)
                        }
                    },  {
                        field:'single_count_total', width: 90, title: '总支数', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/salesRanking/searchHeard', this.pickerDate,this.formData,null,this.getList)
                       }
                   }, {
                        field:'weighing_heavy_total', width: 100, title: '总吨数', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 3})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/salesRanking/searchHeard', this.pickerDate,this.formData,null,this.getList)
                       }
                   }, {
                        field:'kick_back_total', width: 100, title: '总佣金', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/salesRanking/searchHeard', this.pickerDate,this.formData,null,this.getList)
                       }
                   }, {
                        field:'amount_of_product_total', width: 120, title: '总产品金额', titleHelp: {message: '销售总产品金额 = 产品金额(不开票) + 产品金额(开票)'}, formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/salesRanking/searchHeard', this.pickerDate,this.formData,null,this.getList)
                       }
                   },  {
                        field:'money_total', width: 120, title: '销售总金额', titleHelp: {message: '销售总金额 = 金额(不开票) + 金额(开票)'}, formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                        }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/salesRanking/searchHeard', this.pickerDate,this.formData,null,this.getList)
                        }
                    }, {
                        field:'naked_into_warehouse_cost_total', width: 110, title: '总进仓成本', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/salesRanking/searchHeard', this.pickerDate,this.formData,null,this.getList)
                       }
                   }, {
                        field:'naked_price_total', width: 100, title: '总产品裸价',titleHelp: {message: '总产品裸价 = 产品裸价(不开票) + 产品裸价(开票) = 销售总金额 - 总佣金 - 税金'}, formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/salesRanking/searchHeard', this.pickerDate,this.formData,null,this.getList)
                       }
                   },
                    {
                        field:'single_kd_cost_total', width: 100, title: '总库存成本',titleHelp: {message: '库存成本 = 支数 * (存货成本核算表)(该月)每支销售成本'}, formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                        }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/salesRanking/searchHeard', this.pickerDate,this.formData,null,this.getList)
                        }
                    },
                    {
                        field:'gross_margin', width: 100, title: '毛利',titleHelp: {message: '毛利 = 销售裸价 - 库存成本'}, formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                        }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/salesRanking/searchHeard', this.pickerDate,this.formData,null,this.getList)
                        }
                    },
                ],
                tableDataMain: [],
                filterData: {},
                sortData: {"weighing_heavy_total": "desc"},
           }
       },
        methods: {
            cellClassName ({column}) {
                if (column.property === 'single_count_tax' || column.property === 'weighing_heavy_tax' || column.property === 'kick_back_tax'
                    || column.property === 'amount_of_product_tax' || column.property === 'taxes_fees_total'
                    || column.property === 'naked_price_tax' || column.property === 'money_tax'
                    || column.property === 'naked_into_warehouse_cost_total' || column.property === 'practical_price_total') {
                    return 'col-success'
               }
           },
            open(row){
                const{href} = this.$router.resolve({path: '/allWarehouse/allSalesBilling',
                    query: {sales_man:row.sales_man,customer:row.customer,category: row.category,specification:row.specification,
                    pickerDate:[XEUtils.toDateString(this.pickerDate[0], 'yyyy-MM-dd'),
                    XEUtils.toDateString(this.pickerDate[1], 'yyyy-MM-dd')]}});
                window.open(href,'_blank');
           },
            footerMethod({columns, data}) {
                const sums = []
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('合计')
                   } else {
                        if (column.property != 'sales_man'
                        ) {
                            sums.push(this.$_sumNum(data, column.property))
                       } else {
                            sums.push('')
                       }
                   }
               })
                //this.autoComputed();
                // 返回一个二维数组的表尾合计
                return [sums]
           },
            handlePageChange ({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.getList();
           },

            editMethod ({row, column}) {
                const $table = this.$refs.xGrid
                // 重写默认的覆盖式，改为追加式
                $table.setActiveCell(row, column)
           },
            formatDate (value, format) {
                return XEUtils.toDateString(value, format)
           },
            tableRowClassName ({rowIndex}) {
                if (rowIndex%2 === 1) {
                    return 'success-row';
               } else{
                    return '';
               }
           },
            onSubmit() {
                //console.log('submit!');
           },
            searchList() {
                this.tablePage.currentPage = 1
                this.getList();
           },
            getList() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据处理中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                this.$axios({
                    method: 'POST',
                    url: '/admin/salesRanking/search',
                    data: {
                        currentPage: this.tablePage.currentPage,
                        pageSize: this.tablePage.pageSize,
                        filterData: this.filterData,
                        sortData: this.sortData,
                        pickerDate: this.pickerDate,
                        formData:this.formData
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    loading.close();
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.tableDataMain = response.data.page.list
                        this.tablePage.pageSize = response.data.page.pageSize
                        this.tablePage.total = response.data.page.totalRow
                        this.tablePage.currentPage = response.data.page.pageNumber
                   }
               }).catch((error) => {
                    loading.close();
                    console.log(error)
               });
           }
       },
        created () {
            this.formData.searchType = this.$route.query.searchType;

            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
                if (this.isBlankVue(this.formData.searchType)){
                    this.$refs.xGrid.hideColumn(this.$refs.xGrid.getColumnByField('customer'))
                }else{
                    this.$refs.xGrid.hideColumn(this.$refs.xGrid.getColumnByField('sales_man'))
                }
                if (this.$_getActionKeyListBoolean(['common/hasLookKdCost'])){
                    this.$refs.xGrid.showColumn(this.$refs.xGrid.getColumnByField('single_kd_cost_total'))
                    this.$refs.xGrid.showColumn(this.$refs.xGrid.getColumnByField('gross_margin'))
                    this.$refs.xGrid.showColumn(this.$refs.xGrid.getColumnByField('naked_into_warehouse_cost_total'))
                    this.$refs.xGrid.showColumn(this.$refs.xGrid.getColumnByField('naked_price_total'))
                }else{
                    this.$refs.xGrid.hideColumn(this.$refs.xGrid.getColumnByField('single_kd_cost_total'))
                    this.$refs.xGrid.hideColumn(this.$refs.xGrid.getColumnByField('gross_margin'))
                    this.$refs.xGrid.hideColumn(this.$refs.xGrid.getColumnByField('naked_into_warehouse_cost_total'))
                    this.$refs.xGrid.hideColumn(this.$refs.xGrid.getColumnByField('naked_price_total'))
                }
                this.searchList();
           })
       }
   };


</script>

<style scoped>
    .header-wrapepr {display:flex; flex-direction:row;justcontent:space-between}
    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }
    .el-form-item__content .el-input{
        width: 130px;
   }
</style>
